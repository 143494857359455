import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Base',
    component: Home,
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/forget',
    name: 'Forget',
    component: () => import(/* webpackChunkName: "Forget" */ '../views/Account/Forget.vue'),
    meta:{
      layout: 'login'
    },
  },
  {
    path: '/activate-account',
    name: 'ActivateAccount',
    component: () => import(/* webpackChunkName: "ActivateAccount" */ '../views/Account/ActivateAccount.vue'),
    meta:{
      layout: 'login'
    },
  },
  {
    path: '/forget-confirmation',
    name: 'ForgetConfirmation',
    component: () => import(/* webpackChunkName: "ForgetConfirmation" */ '../views/Account/ForgetConfirmation.vue'),
    meta:{
      layout: 'login'
    },
  },
  {
    path: '/anak-saya',
    name: 'MyChild',
    component: () => import(/* webpackChunkName: "MyFriends" */ '../views/MyChild/MyChild.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/anak-saya/:id/info',
    name: 'MyChildInfo',
    component: () => import(/* webpackChunkName: "MyFriends" */ '../views/MyChild/MyChildInfo.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/myfriends',
    name: 'MyFriends',
    component: () => import(/* webpackChunkName: "MyFriends" */ '../views/Student/MyFriends.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/myfriends/:id/info',
    name: 'StudentInfo',
    component: () => import(/* webpackChunkName: "StudentInfo" */ '../views/Student/StudentInfo.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/teacher-list',
    name: 'TeacherList',
    component: () => import(/* webpackChunkName: "TeacherList" */ '../views/Teacher/TeacherList.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/teacher-list/:id/info',
    name: 'TeacherInfo',
    component: () => import(/* webpackChunkName: "TeacherList" */ '../views/Teacher/TeacherInfo.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/subject-list',
    name: 'SubjectsList',
    component: () => import(/* webpackChunkName: "SubjectsList" */ '../views/Subjects/SubjectsList.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/payment-list',
    name: 'PaymentList',
    component: () => import(/* webpackChunkName: "SubjectsList" */ '../views/Payment/PaymentList.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/payment-list/:id/detail',
    name: 'PaymentInfo',
    component: () => import(/* webpackChunkName: "SubjectsList" */ '../views/Payment/PaymentDetail.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/payment-list/:id/info',
    name: 'PaymentInfo',
    component: () => import(/* webpackChunkName: "SubjectsList" */ '../views/Payment/PaymentInfo.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/announcement',
    name: 'Announcement',
    component: () => import(/* webpackChunkName: "AnnouncementRead" */ '../views/Announcement/Announcement.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/announcement/read/:id',
    name: 'AnnouncementRead',
    component: () => import(/* webpackChunkName: "AnnouncementRead" */ '../views/Announcement/AnnouncementRead.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/subjectsschedule',
    name: 'SubjectsSchedule',
    component: () => import(/* webpackChunkName: "SubjectsSchedule" */ '../views/Subjects/SubjectsSchedule.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/agenda-list',
    name: 'AgendaList',
    component: () => import(/* webpackChunkName: "AgendaList" */ '../views/Agenda/AgendaList.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/agenda-list/:id/info',
    name: 'AgendaInfo',
    component: () => import(/* webpackChunkName: "AgendaInfo" */ '../views/Agenda/AgendaInfo.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/attendance',
    name: 'Attendance',
    component: () => import(/* webpackChunkName: "attendance" */ '../views/Attendance/Attendance.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/scores',
    name: 'Scores',
    component: () => import(/* webpackChunkName: "Scores" */ '../views/Scores/Scores.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/scores/detail',
    name: 'ScoresDetail',
    component: () => import(/* webpackChunkName: "Scores" */ '../views/Scores/ScoresDetail.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import(/* webpackChunkName: "Account/Account" */ '../views/Account/Account.vue'),
    meta:{
      layout: 'main'
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../views/Login.vue'),
    meta:{
      layout: 'login'
    },
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta:{
      layout: 'main'
    },
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
