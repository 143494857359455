import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    pageIcon: '',
    pageTitle: '',
    showNavbar: null,
    showLoadingDialog: false,
    showPageOverlay: false,
    appThemeColor : 'light-blue lighten-2',
    appInfo: {
      appName: 'Appname',
      schoolName: 'SMP Xavete'
    },
    get selectedStudent() {
      try {
        var s = localStorage.getItem('selectedStudent').toString()
        var r = JSON.parse(s);
        return r;
      } catch {
        return {};
      }
      
    },
    set selectedStudent(value) {
      localStorage.setItem('selectedStudent', JSON.stringify(value));
    },
    get apiToken() {
      return localStorage.getItem('apiToken');
    },
    set apiToken(value) {
      localStorage.setItem('apiToken', value);
    },
    get isLoggedIn() {
      if (localStorage.getItem('isLoggedIn') == 'true'){
        return true;
      }else{
        return false;
      }
    },
    set isLoggedIn(value) {
      localStorage.setItem('isLoggedIn', value);
    },
    loginInfo: {
      get username() {
        return localStorage.getItem('username');
      },
      set username(value) {
        localStorage.setItem('username', value);
      },
      get firstName() {
        return localStorage.getItem('firstName');
      },
      set firstName(value) {
        localStorage.setItem('firstName', value);
      },
      get lastName() {
        return localStorage.getItem('lastName');
      },
      set lastName(value) {
        localStorage.setItem('lastName', value);
      },
      get email() {
        return localStorage.getItem('email');
      },
      set email(value) {
        localStorage.setItem('email', value);
      },
      get profileImage() {
        return localStorage.getItem('profileImage');
      },
      set profileImage(value) {
        localStorage.setItem('profileImage', value);
      },
      get userType() {
        return localStorage.getItem('userType');
      },
      set userType(value) {
        localStorage.setItem('userType', value);
      },
      get students() {
        return localStorage.getItem('students');
      },
      set students(value) {
        localStorage.setItem('userType', value);
      },
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
