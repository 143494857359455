<template>
  <v-main class="pt-8 bg1">
    <div style="max-width: 860px !important" class="mx-auto">
      <slot></slot>
    </div>
    <LoadingDialog v-if="$store.state.showLoadingDialog"></LoadingDialog>
  </v-main>
</template>

<script>
import LoadingDialog from "../components/LoadingDialog.vue";
export default {
  name: 'LoginLayout',
  components: { LoadingDialog },
  props: {
    config: {
      type: Object,
      default: null
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Ubuntu");

.bg1 {
  background: #0F2027;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#awn-toast-container *,
#awn-popup-wrapper {
  font-family: "Ubuntu" !important;
}
</style>

